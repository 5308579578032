import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AircraftEditComponent } from '../../components/aircraft-edit/aircraft-edit.component';
import { Aircraft } from '../../models/aircraft.model';
import { AircraftService } from '../../services/aircraft.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';


@Component({
    selector: 'app-aircraft-list',
    imports: [
        CommonModule,
        RouterLink,
        AircraftEditComponent,
    ],
    templateUrl: './aircraft-list.component.html',
    styleUrl: './aircraft-list.component.scss'
})
export class AircraftListComponent {
  aircraft: Aircraft[] = [];
  active: boolean = true;

  constructor(public auth: AuthService, public aircraftService: AircraftService, public userService: UserService, private router: Router) {
    this.refreshList()
  } 

  activeAircraft() {
    return this.aircraft.filter(aircraft => aircraft.active);
  }

  showActive() {
    this.active = true;
  }

  showArchived() {
    this.active = false;
  }

  refreshList() {
    this.aircraftService.getAllAircraft().subscribe({
      next: (response) => this.aircraft = response,
      error: (error) => console.error(error),
    });
  }

  selectAircraft(aircraft: Aircraft | null) {
    this.userService.selectAircraft(aircraft);
    if (aircraft != undefined) {
      this.router.navigate(['/search']);
    }
  }

  toggleActive(aircraft: Aircraft) {
    if (aircraft.id === this.userService.user?.selected_aircraft?.id) {
      console.log("Deactivating selected aircraft");
      this.selectAircraft(null);
    }
    aircraft.active = !aircraft.active;
    this.aircraftService.updateAircraft(aircraft).subscribe({
        next: (response) => console.log(response),
        error: (error) => console.error(error),
      });
    }

}
