import { DatePipe, DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { BetaSignup } from '../../models/beta-signup.model';
import { BetaSignupService } from '../../services/beta-signup.service';

@Component({
    selector: 'app-beta-signup-list',
    imports: [
        DecimalPipe,
        DatePipe,
    ],
    templateUrl: './beta-signup-list.component.html',
    styleUrl: './beta-signup-list.component.scss'
})


export class BetaSignupListComponent {
  betaSignups: BetaSignup[] = [];

  constructor(betaSignupService: BetaSignupService) {
    betaSignupService.getAllBetaSignups().subscribe({
      next: (response) => this.betaSignups = response,
      error: (error) => console.error(error),
    });
  } 
}