import { Component } from '@angular/core';
import { Redirect } from '../../models/stripe.model';
import { StripeService } from '../../services/stripe.service';

@Component({
    selector: 'app-billing',
    imports: [],
    templateUrl: './billing.component.html',
    styleUrl: './billing.component.scss'
})
export class BillingComponent {


  constructor(stripeService: StripeService) {
    stripeService.gotoBillingPortal().subscribe({
      next: (redirect: Redirect) => {
        window.location.href=redirect.url
      },
      error: (error) => console.error(error),
    });
  }
}
