import { NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDate, NgbDatepickerModule, NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { Document } from '../../models/document.model';
import { AircraftService } from '../../services/aircraft.service';
import { DocumentService } from '../../services/document.service';
import { PicklistService } from '../../services/picklist.service';
import { UserService } from '../../services/user.service';


@Component({
    selector: 'app-add-document',
    imports: [
        NgbDatepickerModule,
        ReactiveFormsModule,
        NgIf,
        NgbTypeaheadModule,
        RouterModule,
    ],
    templateUrl: './add-document.component.html',
    styleUrl: './add-document.component.scss'
})

export class AddDocumentComponent {
  document: Document
  file: File | null
  serverError: string = '';
  serverSuccess: string = '';
  vendors: string[] = [];

  addDocumentForm:FormGroup =  this.formBuilder.group({
    vendor: ['', [Validators.required]],
    publish_date: [''],
  });

  get vendor() {
    return this.addDocumentForm.get('vendor') as FormControl;
  }

  constructor(private formBuilder: FormBuilder, private documentService: DocumentService, public aircraftService: AircraftService, private picklistService: PicklistService, public userService: UserService) { 
    picklistService.getVendors().subscribe({
      next: (response) => this.vendors = response,
      error: (error) => console.error(error),
    });
  }

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  
  focus$ = new Subject<string>();
	click$ = new Subject<string>();

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
		const inputFocus$ = this.focus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map((term) =>
				(term === '' ? this.vendors : this.vendors.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
			),
		);
	};


  onFileSelected(event: Event) {
    console.log("event: ",event.currentTarget as HTMLInputElement)
    const element = event.currentTarget as HTMLInputElement;
    if (element.files) {
      this.file = element.files[0]
    }
  }

  private zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
  private dateToString = (date: NgbDate) => `${date.year}-${this.zeroPad(date.month,2)}-${this.zeroPad(date.day,2)}`; 

  onSubmit(): void {
    if (!this.file || !this.addDocumentForm.valid) {
      console.log("Form incomplete or file not provided.  Not trying to execute submission");
      return;
    }

    if (!this.userService.user || !this.userService.user.selected_aircraft) {
      this.serverError = 'No aircraft selected';
      this.serverSuccess = '';
      return;
    }
    
    const initialDoc: Partial<Document> = {
      name: this.file.name,
      publish_date: this.dateToString(this.addDocumentForm.get('publish_date')?.value), 
      vendor: this.addDocumentForm.get('vendor')?.value,
    };
    console.log("initialDoc: ",initialDoc);
    
    this.serverError = '';
    this.serverSuccess = "Getting initial document...";
    this.documentService.initialUpload(initialDoc as Document, this.userService.user.selected_aircraft).pipe(
      map(initialResponse => {
        this.document = initialResponse
        // const initialDocument: Document = initialResponse
        let fields = new Map<string, string>(Object.entries(this.document.upload_url.fields));
        const uploadForm = new FormData();
        for (let [key, value] of fields) {
            uploadForm.append(key,value);
          }
          if (this.file){
            uploadForm.append('file', this.file, this.file.name);
          }
          this.serverSuccess = "Uploading document to S3..."
          return {url: this.document.upload_url.url, form: uploadForm};
      }),
      mergeMap(uploadUrl => this.documentService.uploadFileToS3(uploadUrl.url, uploadUrl.form))
    ).pipe(
      map(s3Response => {
        // s3 generally returns a 204 with no additional info
        this.serverSuccess = "Processing document...";
      }),
    // ) // remove this line to re-add code below
      mergeMap(result => this.documentService.processUploadedFile(this.document, this.userService.user!.selected_aircraft!))
    ).subscribe({
      next: (finalDocument) => {
        this.document = finalDocument
        console.log("finalDocument: ",this.document);
        this.serverSuccess = "Document uploaded and queued for processing.";
        this.addDocumentForm.reset();
        this.file = null;
      },
      error: (error) => {
        console.error("Issue with Document creation and upload: ",error)
        this.serverSuccess = '';
        this.serverError = "Error uploading or processing document.";
      },
    })
  }

        
  
}
