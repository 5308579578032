import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-list',
    imports: [DecimalPipe,],
    templateUrl: './user-list.component.html',
    styleUrl: './user-list.component.scss'
})
export class UserListComponent {
  users: User[] = [];

  constructor(userService: UserService) {
    userService.getAllUsers().subscribe({
      next: (response) => this.users = response,
      error: (error) => console.error(error),
    });
  }
}

