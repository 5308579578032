import { Component } from '@angular/core';

@Component({
    selector: 'app-privacy',
    imports: [],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {

}
