import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyAuthenticatorModule, AuthenticatorService } from '@aws-amplify/ui-angular';
import { from, map, mergeMap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';



@Component({
    selector: 'app-login',
    imports: [
        AmplifyAuthenticatorModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
  calledLogin: boolean = false;

  @Input() initialState: any = 'signIn';

  constructor(
        public router: Router, 
        private route: ActivatedRoute, 
        private auth: AuthService, 
        private amplifyAuthService: AuthenticatorService,
        private userService: UserService) { 

    if ('signIn' === this.initialState) {
      this.amplifyAuthService.toSignIn();
    } else if ('signUp' === this.initialState) {
      this.amplifyAuthService.toSignUp();
    }
    
  }

  
  completeLogin() {
    // guard against multiple calls
    if (this.calledLogin) {
      return;
    }
    this.calledLogin = true;
    // force population of the auth admin property
    from(this.auth.isAdmin()).pipe(
      map(() => {
        // console.log("Finished populating admin property: " + this.auth.admin);
      }),
      // force population of the user state
      mergeMap(response => from(this.userService.populateUserState()))
    ).subscribe({
      next: (response) => {
        console.log("Finished populateUserState in Login: ");  

        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/search';
        console.log("Redirecting to: " + returnUrl);
        this.router.navigate([returnUrl])
      },
      error: (error) => {
        console.error("Error waiting: " + error);
      },
    })
  }

}

  