import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgbCollapseModule, NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';


@Component({
    selector: 'app-header',
    imports: [
        CommonModule,
        RouterModule,
        NgbNavModule,
        NgbCollapseModule,
        NgbDropdownModule,
        AmplifyAuthenticatorModule
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isNavbarCollapsed = true;

  constructor(public auth: AuthService, private router: Router, public userService: UserService) {
  }  

  signOut() {
    this.auth.signOut();
    // this.aircraftService.clearSelectedAircraft();
    this.userService.signOut();
    this.router.navigate(['/']);
  }

}
