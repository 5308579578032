import { DatePipe } from '@angular/common';
import { Component, HostListener, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerComponent, PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { Reference } from '../../models/reference.model';

@Component({
    selector: 'component-reference',
    imports: [
        PdfJsViewerModule,
        DatePipe,
    ],
    templateUrl: './reference.component.html',
    styleUrl: './reference.component.scss'
})
export class ReferenceComponent {
  @Input() reference!: Reference;
  @ViewChild('externalPdfViewer') public externalPdfViewer: PdfJsViewerComponent;

  get encodedPdfUrl() {
    return encodeURIComponent(this.reference.documentUrl);
  }

  // used if we want to open the pdf in a new tab
  // public openPdf() {
  //   console.log("opening pdf in new tab!");
  //   this.externalPdfViewer.pdfSrc = encodeURIComponent(this.reference.documentUrl);
  //   this.externalPdfViewer.page = this.reference.page;
  //   this.externalPdfViewer.refresh();
  // }

  private modalService = inject(NgbModal);

  // Followed example from https://dev.to/nicolus/closing-a-modal-with-the-back-button-in-ionic-5-angular-9-50pk
  // to close the modal when the user clicks the back button
  ngOnInit() {
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, '');
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  openFullPageModal(content: TemplateRef<any>) {
		this.modalService.open(content, { fullscreen: true });
	}

  @HostListener('window:popstate', ['$event'])
  dismiss(event: any) {
    this.modalService.dismissAll();
  }
}
