
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { BetaSignup } from '../../models/beta-signup.model';
import { BetaSignupService } from '../../services/beta-signup.service';

@Component({
    selector: 'app-beta-signup',
    imports: [
        ReactiveFormsModule,
        NgIf,
    ],
    templateUrl: './beta-signup.component.html',
    styleUrl: './beta-signup.component.scss'
})
export class BetaSignupComponent {
  private betaSignup: BetaSignup;
  serverError: string = '';
  serverSuccess: string = '';

  betaSignupForm =  this.formBuilder.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    notes: ['', [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder, private betaSignupService: BetaSignupService) { 
  }

  get name() {
    return this.betaSignupForm.get('name') as FormControl;
  }

  get email() {
    return this.betaSignupForm.get('email') as FormControl;
  }

  get notes() {
    return this.betaSignupForm.get('notes') as FormControl;
  }

  onSubmit(): void {

    if (this.betaSignupForm.valid) {
      this.betaSignup = this.betaSignupForm.value;
      Object.assign(this.betaSignup, this.betaSignupForm.value);
      this.betaSignupService.addBetaSignup(this.betaSignup).subscribe({
        next: data => {
          this.serverSuccess = "We've got your signup for our beta, thanks!";
          this.serverError = '';
          this.betaSignupForm.reset();
        },
        error: error => {
          // console.error('There was an error:', error.error.detail);
          this.serverSuccess = '';
          this.serverError = error.error.detail;
          
        }
      });
    }
  }
}
