
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-subscription',
    imports: [NgIf,],
    templateUrl: './subscription.component.html',
    styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {
  potioKey: string = environment.potioKey;
  trustedHtml: any;
  user: User | null = null;


  constructor(private sanitizer: DomSanitizer, public userService: UserService) { 
    console.log("SubscriptionComponent: constructor");
    
  }

  ngOnInit() {
    console.log("SubscriptionComponent: ngOnInit");
    // We need to make sure user is logged in and we have user state before we can render the potio component
    // This results in a what might seem like a redundant call to populateUserState, but it's here to
    // ensure we have the best user and stripe customer data available
    this.userService.populateUserState().then(() => {
      this.user = this.userService.user!; // The AuthGuard protects this page, so we can assume user is not null
      const potioHtml: string = `
    <potio-pricing-table 
          action="payment" 
          key="` + this.potioKey + `"
          customer="`+ this.userService.user?.stripe_customer_id + `">
    </potio-pricing-table>`
      this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(potioHtml);
    });

  }

}




